// .ctrlButton {
//     position: absolute;
//     cursor: pointer;
//     top: 50%;
//     transform: translateY(-50%);
//     z-index: 3;
//     &.left {
//         left: 20px;
//     }
//     &.right {
//         right: 20px;
//     }
// }

.page {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.svgContent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  g path {
    pointer-events: inherit;
  }
}
.canvasObjectSavedSvgContent {
  z-index: 2;
  pointer-events: visible;
}
.canvasSvgContent {
  z-index: 100;
}

.bookViewContainer {
  height: 100%;
}
.bookViewContainer > div {
  height: 100%;
}
