.formText {
  display: none;
  color: black;
}

.list {
  margin: 0.5rem 0rem;
  padding: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #e6e6e6;
}

.videoInfoInput {
  margin: 0px;
}

.listItemDelButton {
  background-color: "#0069d9";
}
