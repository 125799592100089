.vocabularyInfo{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8vmin;
  margin-bottom: 10px;
  padding: 10px 0;
  .kk{
    font-family: 'helvetica neue',helvetica,arial,'microsoft jhenghei','PingFang TC',sans-serif;
    span{
      color: #f00;
    }
  }
  .partOfSpeech{
    padding-left: 10px;
  }
}
