.buttonGroup{
  width: 100%;
  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    padding: 0px 10px;
    border-radius: 5px;
    margin-bottom: 3px;
    cursor: pointer;
    font-size: 16px;
    background-color: #fff;
    appearance: none;
    &.active{
      color:#e5287b;
      background-color: #b2b3b4;
    }
    &:disabled{
      color: #000;
      background-color: #eee;
      cursor: not-allowed;
    }
  }
}