.playbackRateButton{
  justify-content: center;
  width: 40px;
  padding: 5px 10px;
  .playbackRateList{
      display: none;
      position: absolute;
      bottom:100%;
      left: 0;
      width: 100%;
      color:#fff;
      background-color: rgba(0,0,0,.85);
      margin-bottom: -3px;
      &.active{
          display: block;
      }
      .item{
          padding: 5px 10px;
      }
      .currentRate{
          color:#fff;
      }
  }
}