.reader {
	display: flex;
	height: 100%;
	overflow: hidden;

	&.top {
		flex-direction: column-reverse;
	}

	&.bottom {
		flex-direction: column;
	}

	&.left {
		flex-direction: row-reverse;
	}

	&.right {}
}