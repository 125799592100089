.vocabularyExample {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .tabPanel {
    height: 100%;
    &.vocabulary {
      display: flex;
      .left {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .word {
          margin-bottom: 30px;
          font-size: 12vmin;
          font-weight: bold;
        }
        .icon {
          width: 80px;
        }
      }
      .right {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        .image {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
    &.example {
      .title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 70px;
          margin-right: 30px;
          background-image: url('/assets/icon/vocabulary/button.svg');
          background-size: cover;
          color: #fff;
          font-weight: bold;
          font-size: 18px;
          cursor: pointer;
        }
        .info {
          display: flex;
          align-items: flex-start;
          font-size: 8vmin;
          font-weight: bold;
          .audioButton {
            // transform: translateY(50%);
          }
        }
        .tw {
          font-size: 8vmin;
          font-weight: bold;
        }
      }

      .exampleSentence {
        display: flex;
        padding: 15px 0;
        .exampleSentenceContent {
          display: flex;
          flex-direction: column;
        }
        p {
          margin: 0;
          font-size: 8vmin;
          font-weight: bold;
        }
      }
    }
    &.plus,
    &.extra {
      display: block;
      overflow-y: auto; 
      .title {
        display: inline-block;
        padding-bottom: 10px;
        margin-bottom: 10px;
        width: 100%;
        font-size: 6vmin;
        font-weight: 500;
        border-bottom: 1px solid #ccc;
      }
      .content {
        div {
          margin-bottom: 10px;
          font-size: 8vmin;
        }
      }
    }
  }
  .tabSelector {
    position: relative;
    display: flex;
    align-items: flex-end;
    .tabs {
      display: flex;
    }
    .tab {
      padding: 5px 10px;
      margin-right: 10px;
      color: #fff;
      background-color: #cbcccd;
      font-size: 32px;
      font-weight: bold;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      cursor: pointer;
      &.active {
        background-color: #64c8d1;
      }
    }
    .ctrlButtonGroup {
      display: flex;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      .ctrl {
        font-size: 30px;
        color: #64c8d1;
        padding: 5px;
        cursor: pointer;
        &.left {
          margin-right: 5px;
        }
        &.disabled {
          color: #999;
        }
      }
    }
  }
  .vocaSelector {
    position: relative;
    display: flex;
    padding: 5px 30px;
    background-color: #44b2ac;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 1px 10px 1px #ccc;
    .ctrl {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: rgba(255, 255, 255, 0.5);
      font-size: 28px;
      cursor: pointer;
      &.left {
        left: 8px;
      }
      &.right {
        right: 8px;
      }
      &.hide {
        display: none;
      }
    }
    .contentWrapper {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 50px;
      .voca {
        flex: 1;
        padding: 0 15px;
        color: #fff;
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        & + .voca {
          border-left: 1px solid #fff;
        }
        &.active {
          color: #feff02;
        }
        &.disabled {
          color: #999;
          pointer-events: none;
        }
      }
    }
  }

  .audioButton {
    display: flex;
    align-items: center;
    width: 60px;
    height: 100%;
    .icon {
      width: 50px;
    }
  }
}
