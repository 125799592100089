@import '../../../style/media';

// .MobileReaderTools{
    // position: absolute;
    // top:0;
    // left: 0;
    // width: 100vw;
    // height: 100%;
    // z-index: 1000;
    
    .mask{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .toolsWrapper{
        display: none;
        z-index: 10;
        &.show{
            display: block;
        }

        .topTools{
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 45px;
            padding: 5px 15px;
            background-color: #000;
            .button{
                text-align: center;
                color:#fff;
            }
        }
    
        .toolList{
            display: none;
            position: absolute;
            top:45px;
            right: 0;
            width: 200px;
            background-color:rgba(0,0,0,0.9);
            color:#fff;
            font-size: 14px;
    
            &.show{
                display: block;
            }
    
            .list{
                padding: 8px 16px;
            }
        }
    
        .bottomTools{
            display: flex;
            align-items: center;
            position: absolute;
            bottom:0;
            left: 0;
            width: 100%;
            height: 50px;
            padding: 5px 10px;
            background-color:rgba(0,0,0,0.9);
            z-index: 10;
            .button{
                flex:1;
                text-align: center;
                color:#fff;
            }
        }    
    }


    .toggleToolsButton{
        display: none;
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 1px 1px #ccc;
        background-color: #fff;
        &.show{
            display: block;
        }
    }
// }