.lineWidthSlider {
	display: flex;
	align-items:center;
	.item{
		flex: 1;
		padding: 0 10px;
		select{
			width: 100%;
		}
	}
}
