.four-zoom-in {
    width: 48%;
    margin-right: 25px;
    margin-bottom: 2px;


    .box {
        width: 100%;
        display: flex;

        img {
            width: 100%;
            height: 100%;
        }
    }
}
