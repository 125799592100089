header {
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(20%, 20%, 40%, 0.5);
  position: fixed;
  z-index: 999;
  .container {
    width: 70%;
    display: flex;
    align-items: center;
  }
  .top {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    .logoBox {
      width: 50%;
      .logo {
        cursor: pointer;
      }
    }
    .funBox {
      width: 50%;
      display: flex;
      // .search_box {
      //     width: 50%;
      //     .search {
      //         outline: 0;
      //         border-radius: 50px;
      //         border: 1px solid #eee;
      //         padding: 5px 10px;
      //     }
      // }
      .iconBox {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .book {
          width: 30px;
          height: 30px;
          background-color: #eee;
          border-radius: 50%;
          margin: 0 5px;
        }
        .member {
          width: 32px;
          height: 32px;
          background-color: #ccc;
          border-radius: 50%;
          margin: 0 5px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        // .login {
        //   padding: 5px 10px;
        //   color: #fff;
        //   background-color: #ccc;
        //   cursor: pointer;
        //   &:hover {
        //     background-color: #eee;
        //     color: #414141;
        //   }
        // }
      }
      .version-info {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    background-color: #f0f0f0;
    .list_box {
      width: 90%;
      display: flex;
      // justify-content: space-around;
      list-style: none;
      .list {
        cursor: pointer;
        a {
          text-decoration: none;
          color: #414141;
          &:hover {
            color: #aaa;
          }
        }
      }
    }
    .sopping_car {
      width: 10%;
      .car {
        width: 20px;
        height: 20px;
        background-color: #ccc;
      }
    }
  }
}

.mat {
  width: 100%;
  height: 105px;
}

.loginBox {
  .login {
    padding: 0.375rem 0.35rem;
    color: #fff;
    background-color: #ccc;
    border-radius: 0.25rem;
    line-height: 1.5;
    font-weight: 700;
    cursor: pointer;
    &.in {
      background-color: #28a745;
      &:hover {
        background-color: #218838;
      }
    }
    &.out {
      background-color: #545b62;
      &:hover {
        background-color: #5a6268;
      }
    }
  }
  .dropdownBox {
    position: relative;
    .dropdownMenu {
      display: none;
      min-width: 10rem;
      padding: 0.5rem;
      margin: 0.125rem 0 0;
      font-size: 1rem;
      color: #212529;
      text-align: center;
      list-style: none;
      background-color: #fff;
      border-radius: 0.25rem;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      .dropdownItem {
        padding: 3px 10px;
        margin: 10px 0;
        font-weight: 400;
        color: #212529;
        cursor: pointer;
        &:hover {
          background-color: #f8f9fa;
        }
      }
      &.active {
        display: block;
      }
    }
  }
}
