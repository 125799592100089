@import '../../style/media';
.sidebar {
  padding: 10px;
  transition: 0.3s;
  background-color: #51464c;
  color: #fff;
  z-index: 5;
  
  &.vertical{
    max-width: 280px;
    height: 100%;
    transform: translateX(100%);
  }

  &.horizontal{
    width: 100%;
    transform: translateY(100%);
  }

  &.covered {
    position: relative;
    width: 0;
    padding: 0;
    &.show {
      width: 25%;
      transition: 0.1s;
      @include phone {
        width: 75%;
      }
    }
  }

  &.float {
    position: absolute;
    top: 0;
    width: 25%;
    @include phone {
      width: 90%;
    }

    &.vertical{
      width: 25%;
    }
  
    &.horizontal{
      width: 100%;
    }

    overflow-y: auto;
    &.right {
      right: 0;
      transform: translateX(100%);
    }
    &.left {
      left: 0;
      transform: translateX(-100%);
    }
    &.bottom {
      top: initial;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
    }
    &.show {
      transform: translateX(0);
      transition: 0.3s;
    }
  }

  &.isWithMask {
    z-index: 100000;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9;
}
