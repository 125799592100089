.svgWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    // height: calc(100vh - 60px);
    svg {
        width: 100%;
        height: 100%;
    }
}

.svgContent {
    width: 100%;
    height: 100%;
    &.disableSelection {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
