.activityPanel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    &.disable {
        display: none;
    }
    &.enable {
        display: block;
    }
}

.offset {
    height: 50px; /* 高度 120 */
    width: 50px; /* 寬度 120*/
    background-color: rgb(255, 255, 255); /* 背景色 藍色*/
    border: 2px #000 solid; /* 虛線邊框 2 像素 深藍色*/
    margin: 2px; /* 四周邊界 20 像素 */
    float: left;
    color: #000;
    cursor: pointer;
}

.onset {
    height: 50px; /* 高度 120 */
    width: 50px; /* 寬度 120*/
    background-color: rgb(255, 208, 0); /* 背景色 藍色*/
    border: 2px #000 solid; /* 虛線邊框 2 像素 深藍色*/
    margin: 2px; /* 四周邊界 20 像素 */
    float: left;
    color: #fff;
    cursor: pointer;
}

.selected-seat {
    height: 50px; /* 高度 120 */
    width: 50px; /* 寬度 120*/
    background-color: rgb(255, 0, 0); /* 背景色 藍色*/
    border: 2px #000 solid; /* 虛線邊框 2 像素 深藍色*/
    margin: 2px; /* 四周邊界 20 像素 */
    color: #fff;
    float: left;
    pointer-events: none;
}

.content {
    position: absolute;
    top: 0;
    left: 10%;
    right: 10%;
    &.mobile {
        display: flex;
    }
    &.browser {
        display: flex;
    }
}

.contentBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    &.disable {
        cursor: auto;
    }
    &.enable {
        cursor: pointer;
    }
}

.setArea {
    &.half {
        width: 30%;
    }
    &.mobile {
        width: 100%;
    }
    &.full {
        width: 100%;
        height: 0px;
        margin: 5%;
    }
    //background-color:blueviolet;
}

.qrCodeArea {
    &.disable {
        width: 0%;
        height: 0px;
        padding: 0px;
    }
    &.enable {
        width: 80%;
        max-width: 60%;
    }
}
.qrCode {
    width: 90%;
    height: 90%;
    margin: 5%;
}
