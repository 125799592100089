.extendedContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 98vw;
  height: 98vh;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #f4f4f4;
  font-family: Arial, Microsoft JhengHei, 微軟正黑體, -apple-system,
    BlinkMacSystemFont;

  .contentView {
    border-radius: 10px;
    overflow: auto;
    color: #000;
    background-color: #fff;
    padding: 5px 10px;
    height: 100%;

    & > div {
      height: 100%;
    }

    // 數學套件的DIV高度設置
    & > div > div {
      height: 100%;
    }
  }

  // 轉檔多出來的br標籤
  br {
    display: none;
  }
}
