.svgContent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
.canvasObjectSavedSvgContentEnable {
  z-index: 2;
  pointer-events: auto;
}
.canvasObjectSavedSvgContentDisable {
  z-index: 2;
  pointer-events: none;
}
