.colorSelector{
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
    .color{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 3px solid #fff;
      margin-right: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      &.active{
        border-color:#333;
      }
      &.customerColor{
        position: relative;
        overflow: hidden;
        background: linear-gradient(to bottom, #ff0000 14%,#ffa830 28%,#f4ff28 42%,#28ff2f 56%,#005dff 70%,#7200ff 84%,#ff00cb 100%);
        label{
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        input{
          position: absolute;
          display: none;
        }
      }
    }
}