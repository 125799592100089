.playlist{
  flex: 0 0 30%;
  height: 100%;
  background-color: rgba(0,0,0,.85);
  max-height: 400px;
  overflow-y: auto;
  .playlistItem{
    display:flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    .poster{
      display:flex;
      align-items: center;
      width: 80px;
      .img{
        width: 100%;
        padding-bottom:100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .title{
      display: flex;
      align-items: center;
      color:#fff;
      font-size: 14px;
      padding: 0 10px;
    }
  }
}