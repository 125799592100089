.position_box{
    .title{
        margin-bottom: 5px;
    }

    .position{
        display: flex;
        flex-wrap: wrap;

        .option{
            width: 50%;
            margin: 5px 0;
        }
    }
}