.panelContainer{
    padding: 10px 15px;
}


.division_line {
    width: 90%;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
}
