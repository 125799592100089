.readerTools {
	position: relative;
	display: flex;
	justify-content: space-between;
	background-color: #51464C;
	width: 100%;
	height: 10vh;

	&.top {
		border-bottom: 1px solid #ccc;
		flex-direction: row;
	}

	&.bottom {
		border-top: 1px solid #ccc;
		flex-direction: row;
	}

	&.left {
		width: 80px;
		height: 100%;
		flex-direction: column;

		.right {
			width: 100%;
			height: 20%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;
		}

		.middle {
			width: 100%;
			height: 70%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;
		}

		.left {
			width: 100%;
			height: 10%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;
		}

		.text {
			display: none;
		}
	}

	&.right {
		width: 80px;
		height: 100%;
		flex-direction: column;

		.right {
			width: 100%;
			height: 20%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;
		}

		.middle {
			width: 100%;
			height: 70%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;		
		}

		.left {
			width: 100%;
			height: 10%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;
		}

		.text {
			display: none;
		}
	}

	.left {
		// width: 10%;
		display: flex;
		align-items: center;
		background-color: #000;
	}

	.middle {
		// width: 70%;
		background-color: #51464C;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		overflow-x: auto;
		overflow-y: hidden;
		&::-webkit-scrollbar{
			height: 10px;
			background-color: #725f5a;
		}
		&::-webkit-scrollbar-thumb {
			box-shadow: inset 0 0 6px rgba(0,0,0,.3);
			background-color: #cf9482;
		}	
	}

	.right {
		// width: 20%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-color: #000;
	}

	.text {
		width: 100%;
		font-size: .83vmax;
	}

	.toolBtn {
		position: relative;
		min-width: auto;
	}

	.addBtn {
		width: 100%;
		height: 100%;
		color: #fff;
		position: relative;
		overflow: hidden;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 10px;
	}

	.addFileChange {
		position: absolute;
		top: 0;
		right: 0;
		min-width: 100%;
		min-height: 100%;
		font-size: 100px;
		text-align: right;
		filter: alpha(opacity=0);
		opacity: 0;
		outline: none;
		background: white;
		cursor: inherit;
		display: block;
	}
}


.mobileReaderTools {
	position: fixed;
	right: 10px;
	bottom: 10px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #ccc;
	box-shadow: 1px 1px 1px 1px #ccc;
	background-color: #fff;
}


// 4K screen
// @media screen and (min-width: 3000px) {
// 	.readerTools {
// 		height: 300px;
// 	}
// }

// 1280 screen
@media screen and (max-width: 1280px) {
	.readerTools {
		height: 11vh;
	}
}