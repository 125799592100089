.button {
  position: relative;
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  appearance: none;
  background-color: transparent;
  color: #fff;
  border:0px;
  outline: none;
  cursor: pointer;
  i {
      color: #fff;
  }
  &.active{
    color:#f00;
  }
}