
input.rangeSlider[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
	width: 100%;
  background-color: transparent;
  border-radius: 5px;
}
input.rangeSlider:focus {
  outline: none;
}
input.rangeSlider::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #593e65;
  border-radius: 1px;
  border: 0px solid #000000;
  border-radius: 5px;
}
input.rangeSlider::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #fff;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
  border-radius: 50%;
}