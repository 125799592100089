.playbackRateControl{
  position: relative;
  display: flex;
  width: 100%;
  max-width: 300px;
  padding: 10px 0;
  background-color: #fff;
  font-weight: 900;
  font-size: 20px;
  &:after{
    content:'';
    position: absolute;
    top: calc(75% - 5px);
    left: 0;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 270px 10px 0;
    border-color: transparent #ccc transparent transparent;
  }
  .level{
    flex:1;
    text-align: center;
    padding: 0 10px;
    background-color: #fff;
    cursor: pointer;
    &:after{
      content:'';

    position: relative;
    z-index: 1;
      display: block;
      width: 25px;
      height: 25px;
      margin: auto;
      border-radius: 50%;
      border: 1px solid #ccc;
      background-color: #fff;
      opacity: 0;
    }
    &.active::after{
      opacity: 1;
    }
  }
}