.markTools {
  top: 0px;
  left: 100px;
  padding-left: 0px;
  position: absolute;
  z-index: 5000;
  background-color: #51464c;
  box-shadow: 4px 4px rgba(129, 129, 129, 0.5);
  opacity: 0;
}

.markTools li {
  display: inline-block;
}
