.wordLearningGrid {
  display: flex;
  width: 100%;
  height: 100%;
  margin: -10px;
  background-color: #fff;
  .left {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 100%;
    padding: 10px 20px;
    direction: rtl;
    .word {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      margin-bottom: 8px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: -2px 0px 10px 0px #aaa;
      button {
        appearance: none;
        font-size: 18px;
        color: #000;
        background-color: #fff;
        outline: none;
      }
      cursor: pointer;
      &.optional {
        button {
          color: #00f;
        }
      }
      &.active {
        background-color: #fdf4be;
        button {
          background-color: #fdf4be;
        }
      }
    }
  }

  .center {
    position: relative;
    flex: 3;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    height: 100%;
    padding: 0 10px;
    overflow-x: scroll;
    direction: rtl;
    .video {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      box-shadow: 2px 2px 12px 1px #ddd;
      text-align: center;
      padding: 20px 0px;
      height: 85%;
      direction: ltr;
      &.solid {
        &:after,
        &:before {
          content: '';
          background-color: #ccc;
          position: absolute;
        }
        &:after {
          width: 4px;
          height: 100%;
          top: 0;
          left: 50%;
          transform: translateX(calc(-50% + 5px));
        }
        &:before {
          height: 4px;
          width: 100%;
          top: 50%;
          left: 0;
          transform: translateY(calc(-50% - 2px));
        }
      }
      video {
        height: 100%;
      }
      .phonetic {
        height: 100%;
        img {
          height: 100%;
        }
      }
    }
    // .image{
    //   max-width: 100%;
    //   padding-bottom: 90%;
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   margin: 0 auto;
    // }
    // flex-direction: row-reverse;
    .image {
      display: flex;
      width: 100%;
      height: 100%;
      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        direction: ltr;
        img {
          height: 90%;
        }
      }
    }
  }

  .right {
    flex: 1;
    padding: 0px 10px 10px;
    box-shadow: -4px 0px 10px 0px #ccc;
  }
  section {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    .title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      img {
        width: 110%;
        transform: translateX(-5%);
      }
      span {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        color: #fff;
        font-size: 20px;
        z-index: 1;
      }
      // &:after{
      //   content:'';
      //   position: absolute;
      //   top: 50%;
      //   left: -10%;
      //   width: 100%;
      //   height: 100%;
      //   transform: translateY(-50%);
      //   background-image: url('/assets/img/wordGridTitleBg.svg');
      //   background-repeat: no-repeat;
      //   background-position: center;
      //   background-size: contain;
      // }
    }
  }
}
