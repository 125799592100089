.radioWrapper{
    label{
        cursor: pointer;
    }
}

.imgWrapper{
    img{
        width: 100%;
    }
}
.rangeWrapper{
    display: flex;
    label{
        display: flex;
        width: 50%;
        input {
            
        }
    }
}


.discuss{
    border: 1px solid #ccc;
    padding: 5px;
}