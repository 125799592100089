.processBar{
  position: absolute;
  top:0;
  left: 0px;
  transform: translateY(-100%);
  width: 100%;
  height: 6px;
  background-color: rgba(255,255,255,0.6);
  cursor: pointer;
  .processUsage{
      position: absolute;
      top:0;
      left: 0;
      height: 100%;
      background-color: #cc0000;
      transition: .3s;
  }
  .range{
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      margin:0;
      opacity: 0;
      cursor: pointer;
  }
  .timeTooptips{
      opacity: 0;
      position: absolute;
      bottom: 100%;
      transform: translateX(-50%) translateY(-5px);
      padding: 5px;
      background-color: rgba(0,0,0,.85);
      color:#fff;
      font-size: 14px;
  }
  &:hover .timeTooptips{
    opacity: 1;
  }
}