.line_box{
  .content_box{
    margin: 10px 0;
    .content{
      margin: 0 10px;
    }
    .lineWidthSlide{
      display: inline-block;
      width: 100px;
      line-height: 50px;
    }
  }
}