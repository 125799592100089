.radicalInfoBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 15%;
  direction: ltr;
  margin: 10px 0;

  .wrapper {
    display: flex;
    border-radius: 5px;
    box-shadow: -1px 1px 10px 0px #aaa;
    height: 100%;
    &.left {
      flex: 1;
      margin-right: 20px;
    }
    &.right {
      flex: 3;
    }
    .radicalBox {
      display: flex;
      flex: 1;
      height: 100%;
      &.title {
        flex: 0.3;
        background-color: #6cad08;
        font-size: 20px;
      }
      &.orange {
        .label {
          background-color: #f6941f;
          color: #000;
        }
        .value {
          cursor: pointer;
        }
      }
      &.green {
        .label {
          background-color: #9ad806;
          color: #fff;
          text-shadow: 1px 1px #219b01;
        }
        .value {
          color: #9ad806;
        }
      }
      &.blue {
        .label {
          background-color: #03bad7;
          color: #fff;
          text-shadow: 1px 1px #0195f9;
        }
        .value {
          color: #03bad7;
        }
      }
      &.red {
        .label {
          background-color: #ef5a25;
          color: #fff;
          text-shadow: 1px 1px #d70101;
        }
        .value {
          color: #ef5a25;
        }
      }
    }
  }

  .label {
    flex: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    writing-mode: vertical-rl;
    letter-spacing: 1px;
    font-size: 20px;
  }
  .value {
    flex: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    font-weight: bold;
    img {
      width: 100%;
    }
  }
}

// .radicalInfoBar{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 70px;
//   margin-top: 20px;
//   direction: ltr;
//   .radicalBox{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
//     box-shadow: -2px 0px 10px 0px #aaa;
//     .label{
//       padding: 3px;
//       height: 100%;
//       color:#fff;
//       border-radius: 10px;
//       text-align: center;
//       writing-mode: vertical-rl;
//     }
//     .value{
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 60px;
//       height: calc(100% - 5px);
//       border-radius: 10px;
//       font-weight: bold;
//       font-size: 25px;
//     }
//     &.yellow {
//       .label{
//         background-color: #feb32b;
//         color: #000;
//       }
//       .value{
//         background-color: #feed9e;
//       }
//     }
//     &.orange {
//       .label{
//         background-color: #f6941f;
//         color: #000;
//       }
//     }
//     &.green{
//       .label{
//         background-color: #9ad806;
//         color:#fff;
//       }
//       .value{
//         background-color: #fff;
//         color:#9ad806;
//       }
//     }
//     &.purple{
//       .label{
//         background-color: #98188a;
//         color:#fff;
//       }
//       .value{
//         background-color: #f8d6f3;
//         color:#98188a;
//       }
//     }
//     &.blue{
//       .label{
//         background-color: #03bad7;
//         color:#fff;
//       }
//       .value{
//         background-color: #cfe2f1;
//         color:#367eb8;
//       }
//     }
//   }
// }
