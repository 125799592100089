.videoPlayer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 15px;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  color: #f00;
  z-index: 1;
  cursor: pointer;
  font-size: 20px;
}

.video {
  flex: 1 0 75%;
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  text-align: center;
  padding-bottom: 50px;
  video {
    width: 100%;
    height: 100%;
    &::-webkit-media-controls {
      display: none !important;
    }
    &::-webkit-media-controls-enclosure {
      display: none !important;
    }
    &::-webkit-media-text-track-display {
      padding: 3px;
      line-height: 1;
      font-size: 7vmin;
      font-family: Arial, Microsoft JhengHei, 微軟正黑體, -apple-system,
        BlinkMacSystemFont;
    }
  }

  .controlBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    padding: 10px;
    opacity: 0;
    transition: 0.3s;

    .left {
      display: flex;
      align-items: center;
    }

    .right {
      display: flex;
      align-items: center;
    }

    .processBar {
      left: 10px;
      width: calc(100% - 20px);
      border-radius: 5px;
      overflow: hidden;
    }
  }

  &:hover .controlBar {
    opacity: 1;
    transition: 0.3s;
  }
}
