.basePosition{
  margin-bottom: 30px;
  input{
    width: 50px;
  }
  .position{
    display: flex;

    div{
      text-align: center;
      margin: 0px 10px;
    }

    .title{
      margin-right: 10px; 
    }
  }
}