.indicatorContainer {
    background-color: #515151;
    opacity: 0.8;
    z-index: 25000;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
}

.label {
    text-align: center;
    color: black;
    opacity: 1;
    font-size: 8rem;
    width: 75%;
    height: 20%;
    position: fixed;
    top: -25%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.indicator {
    width: 108px;
    height: 108px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
