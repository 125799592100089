.tab {
    display: flex;
    width: 100%;
    overflow-y: auto;
    border: 1px solid #F5B533;
    margin-bottom: 10px;

    .tabItem {
        flex: 1;
        padding: 15px 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        +.tabItem {
            border-left: 1px solid #F5B533;
        }

        &.active {
            border-bottom: 1px solid transparent;
            background-color: #F5B533;
        }
    }
}