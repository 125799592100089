.seekList {
  flex: 0 0 25%;
  height: 100%;
  padding: 10px 0;
  max-height: 100%;
  overflow-y: auto;
  .seekListItem {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #fff;
    cursor: pointer;
    .time {
      margin: 0 10px;
    }
  }
}
