.swipeContainer {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;
    &.reverse {
        flex-direction: row-reverse;
    }

    &.markMode {
        pointer-events: auto;
    }
}


.pinchPanContainer {
    width: 100%;
    height: 100%;
    user-select:auto;
    & > div{
        width: 100%;
        height: 100%;
        user-select:auto;
        & > div{
            width: 100%;
            height: 100%;
        }
    }
}