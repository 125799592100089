$bg: #ececec;
$generator-bg: white;
$padding: 1em;
$title: #ffffff;
$accent-primary: rgb(232, 122, 144);
$accent-secondary: rgb(232, 122, 144);
$text: #212121;

.numberPicker {
  background-color: #fff;

  .generator {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $generator-bg;
    width: 100%;
    height: 100%;
    animation: fadeIn 1s ease-in-out;
    backface-visibility: hidden;
    transition: all 0.5s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .title {
    color: $title;
    font-size: 1.2em;
    padding: $padding;
    background: $accent-secondary;
  }

  .numWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 160px;
  }

  .rNum {
    text-align: center;
    font-size: 20vmin;
    margin: 0;
    color: $text;
    transition: padding 0.2s;
  }

  .inputs,
  .headers {
    display: flex;
    align-content: center;
    flex-flow: row wrap;
    justify-content: space-around;
    color: $text;
    input {
      border: 0;
      font-size: 2em;
      width: 100%;
      border-bottom: 5px solid $accent-secondary;
      background: #f0f0f0;
      padding: 0.2em;
      border-radius: 0;
      transition: margin 0.2s;
      text-align: center;
    }
    p {
      font-size: 1em;
      color: $text;
      font-weight: 400;
    }
    .generate {
      -webkit-appearance: none;
      width: 100%;
      padding: $padding 0;
      color: $title;
      font-size: 1.2em;
      margin: 0;
      display: block;
      background: $accent-primary;
      cursor: pointer;
      transition: background 0.3s;
      width: 100%;
      &:hover {
        background: $accent-secondary;
      }
    }
    .minInput,
    .maxInput {
      position: relative;
      display: flex;
      align-items: center;
      width: 40%;
      margin-bottom: 2em;
      span {
        font-size: 30px;
        padding: 0 10px;
        cursor: pointer;
      }
    }
  }
}

.randoming {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vmin;
  overflow: hidden;
}

ul.digits {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  font-size: 20vmin;
  padding: 0;
  margin: 0;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  line-height: 20vmin;
}

.luckie {
  animation-name: luckie;
}

/* Animations */
@keyframes luckie {
  100% {
    margin-top: -27em;
  }
}
