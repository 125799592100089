.border{
  border: 2px solid #000;
}

.content_box{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;

  .content{
    position: relative;
    width:20.33%;
    margin: 10px 0;
    input{
      display: none;
    }

    .color{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      background-color: #fff;
      cursor: pointer;
    }

    &.transparent:before{
      content:'';
      position: absolute;
      top: 0;
      left: 0;
      right:0;
      margin: 0 auto;
      width: 3px;
      height: 100%;
      background: #f00;
      transform: rotate(45deg);
    }

  }
}