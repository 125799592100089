.baseSize{
    margin-bottom: 30px;
    input{
        width: 50px;
    }
    .size{
        display: flex;
        margin-top: 30px;

        div{
          text-align: center;
          margin: 0 10px;
        }

        .title{
          margin-right: 10px; 
        }
    }
}
