.musicModal{
  position : absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  .close{
    position: absolute;
    top:0;
    right:0;
    color:#f00;
    z-index: 1;
    cursor: pointer;
  }
}