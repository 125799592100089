// 直書型
.word_section {

    &.phonetic{
        &.fontMedium{
            font-size: 8vmin !important;
        }
    }


    &.fontLarge {
        font-size: 10vmin !important;
    }

    &.fontMedium {
        font-size: 7vmin !important;

        .article{
            .circle{
                width: 70px;
                height: 70px;
                margin-right: 54px;
            }
        } 
    }

    &.fontSmall {
        font-size: 4vmin !important;

        .article{
            .circle{
                width: 50px;
                height: 50px;
                margin-right: 27px;
            }

            .tc{
                margin-right: .8em
            }
        } 
    }

    // 轉檔出來多的p標籤
    &~p {
        display: none;
    }
}


// 題目型系列
.topic {

    &.fontLarge {
        font-size: 10vmin;
    }

    &.fontMedium {
        font-size: 7vmin;
    }

    &.fontSmall {
        font-size: 4vmin;
    }

    // 轉檔出來多的p標籤
    &~p {
        display: none;
    }
}

// 純文字型
.plain_text {

    &.fontLarge {
        font-size: 10vmin;

        .title {
            font-size: 13vmin;
        }
    }

    &.fontMedium {
        font-size: 7vmin;

        .title {
            font-size: 10vmin;
        }
    }

    &.fontSmall {
        font-size: 4vmin;

        .title {
            font-size: 7vmin;
        }
    }

    // 轉檔出來多的p標籤
    &~p {
        display: none;
    }

}

// 填充型
.fill {

    &.fontLarge {
        font-size: 10vmin;
    }

    &.fontMedium {
        font-size: 7vmin;
    }

    &.fontSmall {
        font-size: 4vmin;
    }

    // 轉檔出來多的p標籤
    &~p {
        display: none;
    }
}

// 問答結果顯示
#result {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    color: steelblue;
    display: flex;
    align-items: center;
    justify-content: center;

    .good {
        font-size: 100px;
        color: #0f0;
    }

    .bad {
        font-size: 100px;
        color: #f00;
    }
}

// 轉檔多轉出來的標籤
.MsoNormal {
    display: none;
}

// 轉檔出來的table下的p標籤顯示
.MsoNormalTable p {
    display: block;
}

// 針對注音標籤樣式調整
hruby{
    line-height: 1.8 !important;
}