.formGroup{
  display: flex;
  padding: 5px 0;
  &.vertical{
    flex-direction: column;
  }
  .label{
    font-size: 15px;
    font-weight: bold;
    color: #fff;
  }
}