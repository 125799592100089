.extendedContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 98vw;
    height: 98vh;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #f4f4f4;

    .contentView {
        height: 90%;
        border-radius: 10px;
        overflow: auto;
        background-color: #fff;
        padding: 5px 10px;
    }
}