.wordRadical {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  z-index: 1;
  background-image: url('/assets/img/wordRadicalBg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .mainContent {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    padding: 10px;
    .content {
      width: 50%;
      overflow-x: auto;
      overflow-y: hidden;
      text-align: right;
      padding-top: 10px;
      padding-right: 50px;
      direction: rtl;
      img {
        height: 100%;
      }
    }
    .progress {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .progressSlider {
        position: relative;
        width: 70%;
        border: 1px dotted #234554;
        border-radius: 10px;
        cursor: pointer;
        .slider {
          .img {
            display: none;
            width: 100%;
            &.active {
              display: block;
            }
          }
        }
        .icon {
          position: absolute;
          bottom: 10px;
          right: 10px;
          font-size: 4vmin;
        }
      }
    }
    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 10%;
      right: 10px;
      width: 40px;
      height: 40px;
      font-size: 7vmin;
      cursor: pointer;
    }
  }
  .progressModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .progressModalBackground {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }
    .progressModalContent {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      background-color: #fff;
      z-index: 1;
      .itemList {
        display: flex;
        justify-content: center;
        align-items: center;
        .item {
          flex: 1;
          opacity: 0;
          text-align: center;
          margin-bottom: 20px;
          img {
            width: 80%;
          }
          .info {
            display: inline-block;
            font-size: 6vmin;
            background-color: #ccc;
            color: #fff;
            padding: 5px 10px;
          }
        }

        .icon {
          opacity: 0;
          color: #f24;
          font-size: 8vmin;
        }

        .show {
          opacity: 1;
        }
      }
      .controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        > div {
          display: flex;
          width: 33.3333%;
          &.center {
            justify-content: center;
            span {
              font-size: 8vmin;
            }
          }
          &.right {
            justify-content: flex-end;
            span {
              font-size: 7vmin;
            }
          }
        }
        span {
          padding: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
