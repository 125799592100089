.buttonGroup{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0;
  button{
    display: block;
    width: calc( 50% - 10px);
    height: 30px;
    padding: 5px 10px;
    border-radius: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    &.active{
      color:#fff;
      background-color: #ccc;
    }
    &:nth-of-type(odd){
      margin-right:5px;
    }
    &:nth-of-type(even){
      margin-left:5px;
    }
  }
}