.musicPlayer{
  display: flex;
  background-color: rgba(0,0,0,0.65);
  width: 280px;
  height: 50px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.processBar{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.music{
  flex: 1 0 70%;
  position: relative;
  align-content: center;
  audio{
      width: 100%;
  }
  .top{
    display:flex;
    justify-content: center;
    align-content: center;
    width: 100%;
  }
  .bottom{
    display:flex;
    justify-content: center;
    align-content: center;
  }
}