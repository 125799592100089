.messageWrapper{
    display: flex;
    flex-direction: column;
    padding:5px;
    overflow-y:auto;
    height: 200px;
    .message{
        &.right{
            text-align: right;
            align-self: flex-end;
        }
    }
}
.inputWrapper{
    display: flex;
    align-items: center;
}