@import '../../../style/media';
.popover {
  position: absolute;
  width: 20%;
  min-width: 300px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;

  @include phone {
    width: 100%;
  }

  &.top {
    top: 110%;
    left: 50%;
    bottom: initial;
    right: initial;
    transform: translateX(-50%);
  }
  &.bottom {
    bottom: 110%;
    left: 50%;
    top: initial;
    right: initial;
    transform: translateX(-50%);
  }

  &.left {
    top: 50%;
    left: 110%;
    bottom: initial;
    right: initial;
    transform: translateY(-50%);
  }

  &.right {
    top: 50%;
    right: 110%;
    bottom: initial;
    left: initial;
    transform: translateY(-50%);
  }
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.toolTitle {
  padding: 10px 0;
}

.penPanelBtn {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  margin: 0 5px;
  padding: 5px;
  background-color: #fff;
}

.formGroupItemWrapper {
  display: flex;
  align-items: center;
  margin-top: 5px;
  .formGroupItem {
    flex: 1;
  }
}
