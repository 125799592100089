.synopsisWindow{
  display: flex;
  flex-direction: row;
  

  .tab_box{
    // width: 10%;
    // display: flex;
    // flex-wrap: wrap;
    column-count: 1;
    column-gap: 10px;

    .tab{
      width: 50px;
      padding: 10px;
      background-color: #1abcbd;
      color: #fff;
      border-radius: 5px;
      margin: 10px 0;
      margin-left: auto;
      text-align: center;
      font-size: 3vmin;
      cursor: pointer;

      &.active{
        background-color: #d65f84;
      }
    }
  }

  .list{
    width: 90%;
    margin-right: auto;
    height: 100%;
    overflow: auto;
    direction: rtl;

    &>div{
      height: 100%;
    }
  }
}