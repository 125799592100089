.WordExample {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #fff;
  .content {
    display: flex;
    height: 100%;
    overflow-x: auto;
    direction: rtl;
    .item {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 10px 20px;
      .audioWrapper {
        min-height: 50px;
      }
      .audio {
        width: 60px;
        outline: none;
      }
      .image {
        height: 100%;
        img {
          height: 100%;
        }
      }
    }
  }
  .selector {
    position: relative;
    display: flex;
    padding: 5px 30px;
    background-color: #44b2ac;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 1px 10px 1px #ccc;
    .ctrl {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: rgba(255, 255, 255, 0.5);
      font-size: 28px;
      cursor: pointer;
      &.left {
        left: 8px;
      }
      &.right {
        right: 8px;
      }
      &.hide {
        display: none;
      }
    }
    .contentWrapper {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 50px;
      .word {
        flex: 1;
        padding: 0 15px;
        color: #fff;
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        & + .word {
          border-left: 1px solid #fff;
        }
        &.active {
          color: #feff02;
        }
        &.disabled {
          color: #999;
          pointer-events: none;
        }
      }
    }
  }
}
